import React, { useCallback } from 'react';
import { Typography, Button, Box, Grid, IconButton, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import PauseIcon from '@mui/icons-material/Pause';

const PlayerScoringCard = React.memo(({ 
  players, 
  holeNumber, 
  tournamentId, 
  eventId, 
  isPlayoffMode, 
  onBack, 
  onScoreUpdate, 
  updateScore,
  getPlayerScore,
  onHoleChange, 
  totalHoles,
  groupName,
  isUpdating,
  isPaused
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleScoreUpdate = useCallback(async (player, score) => {
    await onScoreUpdate(player, holeNumber, score);
  }, [onScoreUpdate, holeNumber]);

  const handlePrevHole = () => {
    if (holeNumber > 1) {
      onHoleChange(holeNumber - 1);
    }
  };

  const handleNextHole = () => {
    if (holeNumber < totalHoles) {
      onHoleChange(holeNumber + 1);
    }
  };

  return (
    <Box sx={{ p: 2, position: 'relative' }}>
      <IconButton onClick={onBack} size="small" sx={{ position: 'absolute', top: 16, left: 16 }}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4" align="center" sx={{ mb: 3 }}>
        Hole {holeNumber}
      </Typography>
      {!navigator.onLine && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
          <WifiOffIcon color="warning" sx={{ mr: 1 }} />
          <Typography color="warning.main">Offline Mode</Typography>
        </Box>
      )}
      {isPaused && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
          <PauseIcon color="info" sx={{ mr: 1 }} />
          <Typography color="info.main">Updates Queued...</Typography>
        </Box>
      )}
      <Grid container spacing={isMobile ? 1 : 2} justifyContent="center">
        {players.map((player) => (
          <Grid item xs={12} key={player.player_id}>
            <Box sx={{ border: '1px solid #ccc', borderRadius: 2, p: 2, position: 'relative' }}>
              <Typography variant="subtitle1">{player.player_name}</Typography>
              <Grid container spacing={1}>
                {[1, 2, 3, 4, 5, 6].map(score => (
                  <Grid item xs={2} key={score}>
                    <Button
                      fullWidth
                      variant={getPlayerScore(player.player_id, holeNumber) === score ? "contained" : "outlined"}
                      onClick={() => handleScoreUpdate(player, score)}
                      sx={{ 
                        minWidth: 0, 
                        p: '6px',
                        position: 'relative'
                      }}
                    >
                      {score}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row', 
        justifyContent: 'space-between', 
        mt: 3,
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto'
      }}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIosNewIcon />}
          onClick={handlePrevHole}
          disabled={holeNumber === 1}
          fullWidth={isMobile}
          sx={{ 
            borderRadius: '4px', 
            backgroundColor: 'black', 
            color: 'white',
            mb: isMobile ? 2 : 0,
            width: isMobile ? '100%' : '45%'
          }}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          endIcon={<ArrowForwardIosIcon />}
          onClick={handleNextHole}
          disabled={holeNumber === totalHoles}
          fullWidth={isMobile}
          sx={{ 
            borderRadius: '4px', 
            backgroundColor: 'black', 
            color: 'white',
            width: isMobile ? '100%' : '45%'
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
});

export default PlayerScoringCard;
