import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AppProvider, useAuth } from './AppContext';
import LoginForm from './components/LoginForm';
// import SportsSelectionPage from './pages/SportsSelectionPage';
import TournamentSelectionPage from './pages/TournamentSelectionPage';
import GroupRoundSelectionPage from './pages/GroupRoundSelectionPage';
// import TournamentManagementPage from './pages/TournamentManagementPage';
import ScoringPage from './pages/ScoringPage';
import Toolbar from './components/Toolbar';
import CenteredLoading from './components/CenteredLoading';
// import jwtDecode from 'jwt-decode';
// import { refreshToken } from './services/apiService'; 
import Leaderboard from './components/Leaderboard';
import LeaderboardCosmos from './pages/LeaderboardCosmosPage';  // Import the new component
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PlayoffSelectionPage from './pages/PlayoffSelectionPage';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false, // Don't refetch on window focus/changing tabs
      retry: 1, // Retry once on a failed request
    },
  },
});

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  
  // console.log('ProtectedRoute:', { isAuthenticated, loading });

  if (loading) {
    return <CenteredLoading />;
  }
  
  if (!isAuthenticated) {
    console.log('User not authenticated, redirecting to login');
    return <Navigate to="/login" replace />;
  }

  return children;
};

const AppContent = () => {
  const { isAuthenticated, setUser } = useAuth();
  // const { setError } = useLoadingError();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkToken = () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (token) {
        setUser({ token });
      } else {
        setUser(null);
      }
      setLoading(false);
    };

    checkToken();
  }, [setUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {isAuthenticated && <Toolbar />}
      <Routes>
        <Route path="/login" element={isAuthenticated ? <Navigate to="/select-tournament" /> : <LoginForm />} />
        {/* <Route path="/select-sport" element={<ProtectedRoute><SportsSelectionPage /></ProtectedRoute>} /> */}
        <Route path="/select-tournament" element={<ProtectedRoute><TournamentSelectionPage /></ProtectedRoute>} />
        <Route path="/select-group-round" element={<ProtectedRoute> <Navigate to="/select-tournament" /> </ProtectedRoute>} /> 
        <Route path="/select-group-round/:tournamentId" element={<ProtectedRoute><GroupRoundSelectionPage /></ProtectedRoute>} />
        <Route path="/select-group-round/:tournamentId/:eventId" element={<ProtectedRoute><GroupRoundSelectionPage /></ProtectedRoute>} />
        <Route path="/scoring/:tournamentId/:eventId/:teamId" element={<ProtectedRoute><ScoringPage /></ProtectedRoute>} />
        {/* <Route path="/tournament-management" element={<ProtectedRoute><TournamentManagementPage /></ProtectedRoute>} /> */}
        <Route path="/leaderboard" element={<ProtectedRoute><Leaderboard /></ProtectedRoute>} />
        <Route path="/leaderboard-cosmos" element={<ProtectedRoute><LeaderboardCosmos /></ProtectedRoute>} />
        <Route path="/playoff-selection" element={<ProtectedRoute><PlayoffSelectionPage /></ProtectedRoute>} />
        <Route path="/" element={<Navigate to={isAuthenticated ? "/select-tournament" : "/login"} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppProvider>
          <AppContent />
        </AppProvider>
      </Router>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
