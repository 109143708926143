import React, { useState } from 'react';
import { AppBar, Toolbar as MuiToolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AppContext';
import { logout } from '../services/apiService';
import MenuIcon from '@mui/icons-material/Menu';

const StyledToolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
}));

const Logo = styled('img')({
  height: '50px',
});

const Toolbar = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const menuItems = [
    { text: 'Select Tournament', onClick: () => navigate('/select-tournament') },
    { text: 'Create Playoffs', onClick: () => navigate('/playoff-selection') },
    { text: 'Leaderboard', onClick: () => navigate('/leaderboard') },
    { text: 'Logout', onClick: handleLogout },
  ];

  const renderMenuItems = () => (
    <>
      {menuItems.map((item, index) => (
        <Button key={index} color="inherit" onClick={item.onClick} sx={{ mr: 2 }}>
          {item.text}
        </Button>
      ))}
    </>
  );

  return (
    <AppBar position="static" color="default" elevation={1}>
      <StyledToolbar>
        <Logo src={`${process.env.PUBLIC_URL}/pln_black_transparent_logo.png`} alt="PLN Logo" />
        <Typography variant="h6" component="div">
          PLN Scoring
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            >
              <List>
                {menuItems.map((item, index) => (
                  <ListItem key={index} onClick={() => {
                    item.onClick();
                    setDrawerOpen(false);
                  }}>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </>
        ) : (
          <div>{renderMenuItems()}</div>
        )}
      </StyledToolbar>
    </AppBar>
  );
};

export default Toolbar;
