import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fetchLeaderboardData } from '../services/apiService'; // Ensure this import points to the updated function
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  headerCell: {
    backgroundColor: '#005A31',
    color: 'white',
    fontWeight: 'bold',
    padding: '8px',
  },
  playerName: {
    fontWeight: 'bold',
  },
  goodScore: {
    color: '#005A31',
    fontWeight: 'bold',
  },
  badScore: {
    color: '#B22222',
    fontWeight: 'bold',
  },
  neutralScore: {
    color: '#808080',
  },
  cell: {
    padding: '8px',
  },
  button: {
    margin: '16px',
  },
  select: {
    margin: '16px',
  },
}));

const Leaderboard = () => {
  const classes = useStyles();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const [showAllHoles, setShowAllHoles] = useState(false);
  const [selectedLeaderboard, setSelectedLeaderboard] = useState('standard');
  const [selectedTournament, setSelectedTournament] = useState('');
  const [selectedRound, setSelectedRound] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(''); // New state for event
  const [tournaments, setTournaments] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [teams, setTeams] = useState([]);
  const [events, setEvents] = useState([]); // New state for events
  const navigate = useNavigate(); // Initialize useNavigate

  // Callback to apply filters
  const applyFilters = useCallback((data) => {
    console.log("Applying filters: ", { selectedTournament, selectedRound, selectedTeam, selectedEvent });
    const filtered = data.filter(item => {
      return (
        (selectedTournament === '' || item.tournament === selectedTournament) &&
        (selectedRound === '' || item.round === selectedRound) &&
        (selectedTeam === '' || item.team_name === selectedTeam) &&
        (selectedEvent === '' || item.event_name === selectedEvent) // Apply event filter
      );
    });
    console.log("Filtered data: ", filtered);
    setFilteredData(filtered);
  }, [selectedTournament, selectedRound, selectedTeam, selectedEvent]);

  // Callback to fetch data
  const fetchData = useCallback(async () => {
    try {
      const data = await fetchLeaderboardData(selectedLeaderboard);
      setLeaderboardData(data);

      // Extract unique values for dropdowns
      const uniqueTournaments = [...new Set(data.map(item => item.tournament))];
      const uniqueRounds = [...new Set(data.map(item => item.round))];
      const uniqueTeams = [...new Set(data.map(item => item.team_name))];
      const uniqueEvents = [...new Set(data.map(item => item.event_name))]; // Extract unique events

      setTournaments(uniqueTournaments);
      setRounds(uniqueRounds);
      setTeams(uniqueTeams);
      setEvents(uniqueEvents); // Set unique events

      // Apply filters to the fetched data
      applyFilters(data);
    } catch (err) {
      setError('Error fetching leaderboard data');
    }
  }, [selectedLeaderboard, applyFilters]);

  // Fetch data and setup auto-refresh
  useEffect(() => {
    if (selectedLeaderboard === 'cosmos') {
      navigate('/leaderboard-cosmos'); // Navigate to Cosmos leaderboard
    } else {
      fetchData();
      if (autoRefresh) {
        const id = setInterval(fetchData, 10000); // Refresh every 10 seconds
        setIntervalId(id);
        return () => clearInterval(id);
      }
    }
  }, [autoRefresh, selectedLeaderboard, navigate, fetchData]);

  // Apply filters whenever filtering criteria changes
  useEffect(() => {
    applyFilters(leaderboardData); // Reapply filters when leaderboard data changes
  }, [selectedTournament, selectedRound, selectedTeam, selectedEvent, leaderboardData, applyFilters]);

  const handleLeaderboardChange = (event) => {
    setSelectedLeaderboard(event.target.value);
  };

  const handleTournamentChange = (event) => {
    setSelectedTournament(event.target.value);
  };

  const handleRoundChange = (event) => {
    setSelectedRound(event.target.value);
  };

  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value);
  };

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value); // Handle event selection
  };

  const handleStartRefresh = () => {
    setAutoRefresh(true);
  };

  const handleStopRefresh = () => {
    setAutoRefresh(false);
    clearInterval(intervalId);
  };

  const toggleShowAllHoles = () => {
    setShowAllHoles(prev => !prev);
  };

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div>
      <Select
        value={selectedLeaderboard}
        onChange={handleLeaderboardChange}
        className={classes.select}
      >
        <MenuItem value="standard">Standard Leaderboard</MenuItem>
        <MenuItem value="cosmos">CosmosDB Leaderboard</MenuItem>
      </Select>
      <Select
        value={selectedTournament}
        onChange={handleTournamentChange}
        className={classes.select}
      >
        <MenuItem value="">All Tournaments</MenuItem>
        {tournaments.map(tournament => (
          <MenuItem key={tournament} value={tournament}>{tournament}</MenuItem>
        ))}
      </Select>
      <Select
        value={selectedRound}
        onChange={handleRoundChange}
        className={classes.select}
      >
        <MenuItem value="">All Rounds</MenuItem>
        {rounds.map(round => (
          <MenuItem key={round} value={round}>{round}</MenuItem>
        ))}
      </Select>
      <Select
        value={selectedTeam}
        onChange={handleTeamChange}
        className={classes.select}
      >
        <MenuItem value="">All Teams</MenuItem>
        {teams.map(team => (
          <MenuItem key={team} value={team}>{team}</MenuItem>
        ))}
      </Select>
      <Select
        value={selectedEvent}
        onChange={handleEventChange}
        className={classes.select}
      >
        <MenuItem value="">All Events</MenuItem>
        {events.map(event => (
          <MenuItem key={event} value={event}>{event}</MenuItem>
        ))}
      </Select>
      <Button className={classes.button} variant="contained" color="primary" onClick={handleStartRefresh}>
        Start Refresh
      </Button>
      <Button className={classes.button} variant="contained" color="secondary" onClick={handleStopRefresh}>
        Stop Refresh
      </Button>
      <Button className={classes.button} variant="contained" color="default" onClick={toggleShowAllHoles}>
        {showAllHoles ? 'Hide Hole Scores' : 'Show Hole Scores'}
      </Button>
      <TableContainer component={Paper} className={classes.root}>
        <Typography variant="h6" align="center" style={{ padding: '16px', backgroundColor: '#005A31', color: 'white' }}>
          Pro Mini Golf Tournament Leaderboard
        </Typography>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Tournament</TableCell>
              <TableCell className={classes.headerCell}>Event</TableCell> {/* New column for Event */}
              <TableCell className={classes.headerCell}>Player Name</TableCell>
              <TableCell className={classes.headerCell}>Team Name</TableCell>
              <TableCell className={classes.headerCell} align="center">Round Score</TableCell>
              <TableCell className={classes.headerCell} align="center">Holes Completed</TableCell>
              <TableCell className={classes.headerCell} align="center">Rank</TableCell>
              <TableCell className={classes.headerCell} align="center">From Par</TableCell>
              <TableCell className={classes.headerCell} align="center">Round</TableCell>
              {showAllHoles && Array.from({ length: 18 }, (_, i) => (
                <TableCell key={i} className={classes.headerCell} align="center">Hole {i + 1}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((player, index) => (
              <TableRow key={`${player.player_id}-${index}`}>
                <TableCell className={classes.cell}>{player.tournament}</TableCell>
                <TableCell className={classes.cell}>{player.event_name}</TableCell> {/* Event name cell */}
                <TableCell className={`${classes.cell} ${classes.playerName}`}>{player.player_name}</TableCell>
                <TableCell className={classes.cell}>{player.team_name}</TableCell>
                <TableCell align="center" className={`${classes.cell} ${player.round_score < 0 ? classes.goodScore : player.round_score > 0 ? classes.badScore : classes.neutralScore}`}>
                  {player.round_score}
                </TableCell>
                <TableCell align="center" className={classes.cell}>{player.holes_in_1 || 0}</TableCell>
                <TableCell align="center" className={classes.cell}>{player.Rank}</TableCell>
                <TableCell align="center" className={classes.cell}>{player.par}</TableCell>
                <TableCell align="center" className={classes.cell}>{player.round}</TableCell>
                {showAllHoles && Array.from({ length: 18 }, (_, i) => (
                  <TableCell key={i} align="center" className={classes.cell}>
                    {player[`hole_${i + 1}`] !== null ? player[`hole_${i + 1}`] : '-'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Leaderboard;
