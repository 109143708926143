import React, { useState, useEffect, useMemo } from 'react';
import { Container, Typography, Box, FormControl, InputLabel, Select, MenuItem, Checkbox, Button, Divider, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchLiveScoresData, updateLiveScoresWithPlayoff } from '../services/apiService';

const PLAYOFF_TEAM_ID = "57ce0040-6981-488c-b86e-aced9be612a7";
const PLAYOFF_TEAM_NAME = "Playoff Group";

const PlayoffSelectionPage = () => {
  const [selectedTournament, setSelectedTournament] = useState('');
  const [players, setPlayers] = useState([]);
  const [playoffPlayers, setPlayoffPlayers] = useState([]);
  const [playoffRoundInfo, setPlayoffRoundInfo] = useState(null);

  const queryClient = useQueryClient();

  const { data: liveScores, isLoading, error } = useQuery({
    queryKey: ['liveScores'],
    queryFn: fetchLiveScoresData,
  });

  const selectedTournamentData = useMemo(() => {
    if (!liveScores || !selectedTournament) return null;
    return liveScores.find(t => t.tournament_id === selectedTournament);
  }, [liveScores, selectedTournament]);

  useEffect(() => {
    if (selectedTournamentData) {
      const playerData = extractPlayerData(selectedTournamentData);
      const uniquePlayers = playerData.filter((player, index, self) =>
        index === self.findIndex((t) => t.player_id === player.player_id)
      );
      setPlayers(uniquePlayers.map(player => ({ ...player, checked: false })));

      if (hasPlayoffOccurred(selectedTournamentData)) {
        console.log("Playoff has already occurred in this tournament");
        const playoffEvent = selectedTournamentData.events.find(event => 
          event.sport_details && event.sport_details.playoff_occured
        );
        if (playoffEvent && playoffEvent.sport_details.playoff_scoring) {
          const playoffPlayers = playoffEvent.sport_details.playoff_scoring.map(player => ({
            ...player,
            checked: true
          }));
          setPlayoffPlayers(playoffPlayers);
          setPlayoffRoundInfo({
            roundSequence: playoffEvent.round_sequence,
            flowicsRound: playoffEvent.round_sequence - 1
          });
        }
      } else {
        setPlayoffRoundInfo({
          roundSequence: selectedTournamentData.events.length + 1,
          flowicsRound: selectedTournamentData.events.length
        });
      }
    }
  }, [selectedTournamentData]);

  const extractPlayerData = (tournamentData) => {
    if (!tournamentData || !tournamentData.events) return [];

    return tournamentData.events.flatMap(event => {
      const sportDetails = event.sport_details;
      if (!sportDetails || !sportDetails.regulation_scoring) return [];

      return sportDetails.regulation_scoring.map(player => ({
        ...player,
        event_id: event.event_id,
        event_name: event.event_name
      }));
    });
  };

  const hasPlayoffOccurred = (tournamentData) => {
    if (!tournamentData || !tournamentData.events) return false;
    return tournamentData.events.some(event => 
      event.sport_details && event.sport_details.playoff_occured
    );
  };

  const handleTournamentChange = (event) => {
    setSelectedTournament(event.target.value);
    setPlayers([]);
    setPlayoffPlayers([]);
    setPlayoffRoundInfo(null);
  };

  const handlePlayerToggle = (playerId, inPlayoffList = false) => {
    if (inPlayoffList) {
      setPlayoffPlayers(prevPlayers => prevPlayers.filter(player => player.player_id !== playerId));
    } else {
      setPlayers(players.map(player => 
        player.player_id === playerId ? { ...player, checked: !player.checked } : player
      ));
    }
  };

  const handleAddToPlayoff = () => {
    const selectedPlayers = players.filter(player => player.checked);
    setPlayoffPlayers(prevPlayers => {
      const newPlayers = selectedPlayers.filter(player => 
        !prevPlayers.some(p => p.player_id === player.player_id)
      );
      return [...prevPlayers, ...newPlayers].sort((a, b) => 
        a.player_name.split(' ')[1].localeCompare(b.player_name.split(' ')[1])
      );
    });
    setPlayers(players.map(player => ({ ...player, checked: false })));
  };

  const createPlayoffRound = async (tournamentData, playoffPlayers) => {
    const newPlayoffEvent = {
      event_id: `playoff-event-${Date.now()}`,
      event_name: `Playoff Round ${tournamentData.events.length + 1}`,
      event_status: "Started",
      event_date: new Date().toISOString().split('T')[0],
      last_updated: new Date().toISOString(),
      teams: [{ team_id: PLAYOFF_TEAM_ID, team_name: PLAYOFF_TEAM_NAME }],
      round_sequence: tournamentData.events.length + 1,
      sport_details: {
        playoff_occured: true,
        regulation_scoring: null,
        playoff_scoring: playoffPlayers.map(player => ({
          player_id: player.player_id,
          player_name: player.player_name,
          team_id: PLAYOFF_TEAM_ID,
          total_score: null,
          holes: Array(18).fill().map((_, index) => ({
            hole_number: index + 1,
            score: null
          })),
          holes_completed: null,
          from_par: null,
          display_par: null
        }))
      }
    };

    const updatedTournamentData = {
      ...tournamentData,
      events: [...tournamentData.events, newPlayoffEvent],
      total_rounds: tournamentData.total_rounds + 1,
      id: tournamentData.tournament_id
    };

    return await updateLiveScoresWithPlayoff(updatedTournamentData);
  };

  const createPlayoffMutation = useMutation({
    mutationFn: (data) => createPlayoffRound(data.tournamentData, data.playoffPlayers),
    onSuccess: () => {
      queryClient.invalidateQueries(['liveScores']);
      console.log('Playoff round creation successful');
      // TODO: Add success message to UI
    },
    onError: (error) => {
      console.error('Playoff round creation failed:', error);
      // TODO: Add error message to UI
    },
  });

  const handleCreatePlayoff = () => {
    if (hasPlayoffOccurred(selectedTournamentData)) {
      console.error('Playoff has already occurred in this tournament');
      return;
    }

    if (playoffPlayers.length === 0) {
      console.error('No players selected for playoff');
      return;
    }

    if (!selectedTournamentData) {
      console.error('No tournament selected');
      return;
    }

    createPlayoffMutation.mutate({
      tournamentData: selectedTournamentData,
      playoffPlayers: playoffPlayers
    });
  };

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error.message}</Typography>;

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Select Tournament</InputLabel>
          <Select value={selectedTournament} onChange={handleTournamentChange}>
            {liveScores && liveScores.map((tournament) => (
              <MenuItem key={tournament.tournament_id} value={tournament.tournament_id}>
                {tournament.tournament_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {playoffRoundInfo && (
          <Typography variant="subtitle1" gutterBottom>
            Playoff Round: {playoffRoundInfo.roundSequence} (Flowics Round: untitled array={playoffRoundInfo.flowicsRound})
          </Typography>
        )}

        <Typography variant="h6" gutterBottom>Players</Typography>
        <List>
          {players.map((player) => (
            <ListItem key={player.player_id} dense onClick={() => handlePlayerToggle(player.player_id)}>
              <ListItemIcon>
                <Checkbox edge="start" checked={player.checked} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText primary={player.player_name} />
            </ListItem>
          ))}
        </List>

        <Button variant="contained" color="primary" onClick={handleAddToPlayoff} fullWidth>
          Add to Playoff Round
        </Button>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>Playoff Round</Typography>
        <List>
          {playoffPlayers.map((player) => (
            <ListItem key={player.player_id} dense onClick={() => handlePlayerToggle(player.player_id, true)}>
              <ListItemIcon>
                <Checkbox edge="start" checked={true} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText primary={player.player_name} />
            </ListItem>
          ))}
        </List>

        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleCreatePlayoff} 
          fullWidth 
          disabled={playoffPlayers.length === 0 || createPlayoffMutation.isPending || hasPlayoffOccurred(selectedTournamentData)}
          title={hasPlayoffOccurred(selectedTournamentData) ? "Playoff has already occurred for this tournament" : ""}
          sx={{ mt: 2 }}
        >
          {createPlayoffMutation.isPending ? 'Creating Playoff...' : 'Confirm'}
        </Button>

        {createPlayoffMutation.isError && (
          <Typography color="error" sx={{ mt: 2 }}>
            Error creating playoff round. Please try again.
          </Typography>
        )}

        {createPlayoffMutation.isSuccess && (
          <Typography color="success" sx={{ mt: 2 }}>
            Playoff round created successfully!
          </Typography>
        )}

        {hasPlayoffOccurred(selectedTournamentData) && (
          <Typography color="warning" sx={{ mt: 2 }}>
            This tournament has a playoff round.
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default PlayoffSelectionPage;