import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateLiveScores } from '../services/apiService';

export const useScoring = (tournamentId, eventId, isPlayoffMode = false) => {
  const queryClient = useQueryClient();

  const scoreMutation = useMutation({
    mutationKey: ['updateScore', tournamentId, eventId],
    mutationFn: (payload) => updateLiveScores(payload, tournamentId, eventId),
    onMutate: async (newScore) => {
      // Cancel any outgoing refetches so they don't overwrite our optimistic update
      await queryClient.cancelQueries(['tournament', tournamentId]);

      // Snapshot the previous value
      const previousTournament = queryClient.getQueryData(['tournament', tournamentId]);

      // Optimistically update to the new value
      queryClient.setQueryData(['tournament', tournamentId], (old) => {
        if (!old) return old;
        const updatedEvents = old.events.map(event => {
          if (event.event_id === eventId) {
            const scoringKey = event.sport_details.playoff_scoring ? 'playoff_scoring' : 'regulation_scoring';
            const updatedScoring = event.sport_details[scoringKey].map(player => {
              if (player.player_id === newScore.player_id) {
                const updatedHoles = player.holes.map(hole => 
                  hole.hole_number === newScore.holes[0].hole_number
                    ? { ...hole, score: newScore.holes[0].score }
                    : hole
                );
                return { ...player, holes: updatedHoles };
              }
              return player;
            });
            return { 
              ...event, 
              sport_details: { ...event.sport_details, [scoringKey]: updatedScoring } 
            };
          }
          return event;
        });
        return { ...old, events: updatedEvents };
      });

      return { previousTournament };
    },
    onError: (err, newScore, context) => {
      if (context?.previousTournament) {
        queryClient.setQueryData(['tournament', tournamentId], context.previousTournament);
      }
      console.error('Error updating score:', err);
      return { type: 'error', message: 'Failed to update score. Will retry automatically once connection is restored.' };
    },
    onSuccess: (data, variables) => {
      console.log('Score update successful:', variables);
      return { type: 'success', message: `Score updated for ${variables.player_name}` };
    },
    onSettled: () => {
      queryClient.invalidateQueries(['tournament', tournamentId]);
    },
  });

  const updateScore = async (player, holeNumber, score) => {
    const currentScore = getPlayerScore(player.player_id, holeNumber);
    if (currentScore === score) {
      return { type: 'info', message: `Score unchanged for ${player.player_name}` };
    }

    const tournamentData = queryClient.getQueryData(['tournament', tournamentId]);
    const event = tournamentData?.events.find(e => e.event_id === eventId);
    const isPlayoffScoring = !!event?.sport_details.playoff_scoring;

    let payload;
    if (isPlayoffScoring) {
      payload = {
        playoff_scoring: [{
          player_id: player.player_id,
          player_name: player.player_name,
          holes: [{ hole_number: holeNumber, score: score }]
        }]
      };
    } else {
      payload = {
        player_id: player.player_id,
        player_name: player.player_name,
        holes: [{ hole_number: holeNumber, score: score }]
      };
    }

    try {
      const result = await scoreMutation.mutateAsync(payload);
      return { type: 'success', message: `Score updated for ${player.player_name}` };
    } catch (error) {
      console.error('Failed to update score:', error);
      return { 
        type: 'error',
        message: 'Failed to update score. Will retry automatically once connection is restored.'
      };
    }
  };

  const getPlayerScore = (playerId, holeNumber) => {
    const tournamentData = queryClient.getQueryData(['tournament', tournamentId]);
    const event = tournamentData?.events.find(e => e.event_id === eventId);
    const scoringKey = event?.sport_details.playoff_scoring ? 'playoff_scoring' : 'regulation_scoring';
    const player = event?.sport_details[scoringKey]?.find(p => p.player_id === playerId);
    return player?.holes.find(h => h.hole_number === holeNumber)?.score || null;
  };

  return { 
    updateScore, 
    getPlayerScore,
    isUpdating: scoreMutation.isLoading,
    isPending: scoreMutation.isPending,
    isPaused: scoreMutation.isPaused,
    error: scoreMutation.error
  };
};