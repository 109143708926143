import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://plnapi-dev.azurewebsites.net';

const apiInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true
});

let sessionCredentials = null;
let refreshInterval;
let lastRefreshTime = 0;
const REFRESH_COOLDOWN = 60000; // 1 minute cooldown

export const refreshToken = async () => {
  const currentTime = Date.now();
  if (currentTime - lastRefreshTime < REFRESH_COOLDOWN) {
    console.log('Skipping refresh due to cooldown');
    return;
  }

  if (!sessionCredentials) {
    const storedToken = localStorage.getItem('token');
    if (!storedToken) {
      throw new Error('No stored credentials or token for refresh');
    }
    return storedToken;
  }

  try {
    const newToken = await login(sessionCredentials);
    lastRefreshTime = currentTime;
    return newToken;
  } catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
  }
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

const startTokenRefresh = () => {
  // Refresh every 25 minutes
  refreshInterval = setInterval(async () => {
    try {
      await refreshToken();
      console.log('Token refreshed successfully');
    } catch (error) {
      console.error('Failed to refresh token:', error);
      // Handle refresh failure (e.g., redirect to login)
    }
  }, 25 * 60 * 1000);
};

const stopTokenRefresh = () => {
  if (refreshInterval) {
    clearInterval(refreshInterval);
  }
};

const keepAliveCall = async () => {
  if (!isAuthenticated()) return;

  try {
    await apiInstance.get('/api/venues/');
    console.log('Keep-alive call successful');
  } catch (error) {
    console.error('Keep-alive call failed:', error);
    // The error will be handled by the existing interceptor if it's a 500
  }
};

let keepAliveInterval;

const startSessionMaintenance = () => {
  if (!isAuthenticated()) return;
  
  startTokenRefresh(); // Start token refresh process
  // Start keep-alive process
  keepAliveInterval = setInterval(keepAliveCall, 10 * 60 * 1000); // Every 10 minutes
};

export const stopSessionMaintenance = () => {
  stopTokenRefresh(); // Stop token refresh process
  // Stop keep-alive process
  if (keepAliveInterval) {
    clearInterval(keepAliveInterval);
    keepAliveInterval = null;
  }
};

export const login = async (credentials) => {
  try {
    const response = await apiInstance.post('/api/auth/login', credentials);
    const token = response.data[0].access_token;
    if (token) {
      localStorage.setItem('token', token);
      sessionCredentials = { ...credentials }; // Store a copy of the credentials
      startSessionMaintenance();
      return token;
    } else {
      throw new Error('No token received from server');
    }
  } catch (err) {
    console.error('Login error:', err.response ? err.response.data : err.message);
    throw err;
  }
};

export const logout = () => {
  stopSessionMaintenance(); // Stop both token refresh and keep-alive
  localStorage.removeItem('token');
  sessionCredentials = null;
  localStorage.removeItem('username');
  localStorage.removeItem('password');
  localStorage.removeItem('selectedGroup');
  localStorage.removeItem('selectedRound');
  localStorage.removeItem('currentPage');
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith('scores_')) {
      localStorage.removeItem(key);
    }
  });
};

apiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 500) {
      try {
        const newToken = await refreshToken();
        error.config.headers['Authorization'] = newToken;
        return apiInstance(error.config);
      } catch (refreshError) {
        // If refresh fails, log out the user
        console.error('Token refresh failed:', refreshError);
        logout();
        throw new Error('Authentication failed. Please log in again.');
      }
    }
    return Promise.reject(error);
  }
);

// Add a request interceptor
apiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchLiveScoresData = async () => { 
    try {
        const response = await apiInstance.get('/api/liveScores/');
        console.log('Fetched liveScores Data:', response.data);
        return response.data;
    } catch (err) {
        console.error('Error fetching liveScores Data:', err);
        throw err;
    }
};

//React query version of the above api call
export const useLiveScoresData = () => {
  return useQuery({
      queryKey: ['liveScores'],
      queryFn: fetchLiveScoresData,
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
  });
};

export const fetchLiveScoresForTournament = async (tournamentId) => {
    try {
        const response = await apiInstance.get(`/api/liveScores/${tournamentId}`);
        // console.log('Fetched live scores for tournament:', response.data);
        return response.data;
    } catch (err) {
        console.error('Error fetching live scores:', err);
        throw err;
    }
};

export const fetchLeaderboardData = async () => {
  try {
    const response = await apiInstance.get('/api/players/leaderboard');
    console.log('Fetched leaderboard data:', response.data);
    return response.data;
  } catch (err) {
    console.error('Error fetching leaderboard data:', err);
    throw err;
  }
};

export const updateLiveScores = async (payload, tournament_id, event_id) => {
    try {
        const response = await apiInstance.patch(`/api/liveScoresAddScores/tournament/${tournament_id}/event/${event_id}`, payload);
        console.log('Update response:', response.data);
        return { success: true, data: response.data };
    } catch (error) {
        console.error('Error updating live scores:', error);
        throw error;
    }
};

//creates a scoring document in live scores using tournament ID
export const createScoringDocument = async (tournamentId) => {
  try {
    const response = await apiInstance.post(`/api/liveScores/createScoringDocument/${tournamentId}`);
    return response.data;
  } catch (error) {
    console.error('Error creating scoring document:', error);
    throw error;
  }
};

export const updateLiveScoresWithPlayoff = async (liveScoreData) => {
    try {
        const response = await apiInstance.post('/api/liveScores/', liveScoreData);
        return response.data;
    } catch (error) {
        console.error('Error creating live score:', error);
        throw error;
    }
};

//will be used for group/round creation
export const createTeam = async (teamData) => {
  const response = await apiInstance.post('/api/teams/', teamData);
  return response.data;
};

export const updatePlayerTeam = async (playerId, playerData) => {
  const response = await apiInstance.put(`/api/players/${playerId}`, playerData);
  return response.data;
};

export const addTeamToLiveScore = async (tournamentId, eventId, teamData) => {
  const response = await apiInstance.patch(`/api/liveScores/addTeams/tournament/${tournamentId}/event/${eventId}`, teamData);
  return response.data;
};

export const updateEventPlayoffStatus = async (tournamentId, eventId, statusData) => {
  console.log(`updateEventPlayoffStatus API call for tournament ${tournamentId} and event ${eventId} payload:`, JSON.stringify(statusData, null, 2));
  const response = await apiInstance.patch(`/api/events/${tournamentId}/${eventId}/playoff-status`, statusData);
  return response.data;
};

// TOURNAMENT MANAGEMENT

// export const fetchTournaments = async () => {
//   try {
//     const response = await apiInstance.get('/api/tournaments/');
//     console.log('Fetched tournaments:', response.data);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching tournaments:', error.response ? error.response.data : error.message);
//     if (error.response && error.response.status === 401) {
//       console.log('Unauthorized access. Redirecting to login...');
//     }
//     throw error;
//   }
// };

// export const createTournament = async (tournamentData) => {
//   try {
//     const response = await apiInstance.post('/api/tournaments/', tournamentData, {
//       headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'POST,OPTIONS',
//       }
//     });
//     console.log('Tournament created successfully:', response.data);
//     return response.data;
//   } catch (error) {
//     console.error('Error creating tournament:', error.response ? error.response.data : error.message);
//     throw error;
//   }
// };

// export const updateTournament = async (tournamentId, tournamentData) => {
//   try {
//     const response = await apiInstance.put(`/api/tournaments/${tournamentId}/`, tournamentData);
//     return response.data;
//   } catch (error) {
//     console.error('Error updating tournament:', error);
//     throw error;
//   }
// };

// export const createRound = async (roundData) => {
//     try {
//         const response = await apiInstance.post('/api/rounds/', roundData);
//         return response.data;
//     } catch (error) {
//         console.error('Error creating round:', error);
//         throw error;
//     }
// };

// export const createEvent = async (eventData) => {
//     try {
//         const response = await apiInstance.post('/api/events/', eventData);
//         return response.data;
//     } catch (error) {
//         console.error('Error creating event:', error);
//         throw error;
//     }
// };

//hardcoded to reset the players I will be testing
// export const resetPlayerTeams = async (playersToReset) => {
//   const resetPromises = playersToReset.map(player => 
//     updatePlayerTeam(player.playerId, { TeamId: player.originalTeamId })
//   );
  
//   try {
//     await Promise.all(resetPromises);
//     return { success: true, message: 'Players reset successfully' };
//   } catch (error) {
//     console.error('Error resetting player teams:', error);
//     throw error;
//   }
// };

export default apiInstance;